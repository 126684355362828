.home-declaration-holder 
  display: flex
  flex-direction: row
  align-items: flex-start
  justify-content: space-between
  margin-bottom: var(--line-margin)
  .left-logo
    flex: none
    height: 323px
    background-color: var(--main-color)
    color: #fff
    font-size: 35px
    line-height: 38px
    font-family: Taipei Sans TC Beta
    font-weight: 300
    display: flex
    flex-direction: row
    justify-content: center
    align-items: flex-end
    text-align: center
    white-space: pre-line
    padding: 10px
    box-sizing: border-box
  .info-box
    flex: auto
    position: relative
    box-sizing: border-box
    height: 323px
    padding: 0 0 10px 20px
    font-weight: 300
    font-family: SourceHanSansSC
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
    white-space: pre-line
    *
      flex: none
    .border-tip
      width: 100%
      color: #FF5500
      font-size: 12px
      font-family: SourceHanSansSC
    .title-tip
      font-size: 15px
      font-family: SourceHanSansSC
      font-weight: normal
      margin-bottom: 4px
    .title
      font-size: 25px
      font-family: SourceHanSansSC
      font-weight: normal
    .motto
      font-size: 35px
      font-family: Taipei Sans TC Beta
      font-weight: 300
      margin-top: 10px
    .content
      margin: auto 0
      font-size: 20px
      line-height: 30px
      font-family: Taipei Sans TC Beta
      font-weight: 300
      color: #888
