.page-header 
  display: flex
  flex-direction: row
  align-items: flex-start
  justify-content: space-between
  margin-bottom: var(--line-margin)
  .header-logo 
    flex: none
    margin-right: auto
  .header-tip 
    flex: none
    width: 240px
    font-size: 12px
    font-family: SourceHanSansSC
    font-weight: normal
    color: #000000
    margin-right: 51px
  .header-menu-icon 
    flex: none
    width: 25px
    height: 25px
    font-size: 25px
    color: var(--main-color)
  

