.page-footer-holder
  height: 240px
  background: #e84816
  display: flex
  flex-direction: column
  justify-content: space-between
  padding: 10px var(--line-margin)
  box-sizing: border-box
  .content
    display: flex
    justify-content: space-between
    color: white
    margin-top: var(--line-margin)
    &>div
      display: flex
    .content-left
      display: flex
      width: 70%
      justify-content: space-between
      .phone
        display: block
        border: 1px solid white
        padding: 0 6px
        line-height: 20px
      &>div
        display: flex
        flex-direction: column
        margin-right: 40px
        align-items: center
        &>span:nth-child(1)
          font-size: 20px
          display: flex
          flex-direction: column
          align-items: center
          margin-bottom: 10px
          &::after
            content: ""
            display: block
            width: 30px
            height: 2px
            background: white
            margin-top: 10px
    .content-right
      display: flex
      width: 26%
      justify-content: space-between
      &>div
        position: relative
      .QRcode
        position: absolute
        left: -13px
        top: 30px
        display: block
        width: 70.2px
        height: 76.8px
        background-size: contain
        display: none
      .footer-icon
        display: block
        // background: yellow
        width: 50px
        height: 30px
        text-align: center
        font-size: 26px
        cursor: pointer
        &:hover .QRcode
          display: block
          
  .bottom
    border-top: 1px solid white
    display: flex
    justify-content: center
    a
      color: white
      margin-top: 12px
      margin-right: 5px
      font-size: 14px
    span
      color: white
      margin-top: 10px
      font-size: 14px
      margin-right: 20px
