.product-holder 
  display: flex
  justify-content: space-between

  margin-bottom: var(--line-margin)
  .product-poster
    flex: none
    width: 48%
    background-color: var(--main-color)
  .info-box
    flex: none
    box-sizing: border-box
    width: 48%
    color: #4B4B4B
    font-weight: 300
    font-family: Source Han Sans CN
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
    white-space: pre-line
    transform-origin: left left
    *
      flex: none
    .type
      position: relative
      font-size: 20px
      margin-bottom: 20px
      &:before
        content: ''
        position: absolute
        top: 50%
        left: -14px
        border-left: 6px solid #4B4B4B
        border-top: 5px solid transparent
        border-bottom: 5px solid transparent
        border-right: 0
        transform: translate(-50%,-50%)
    .name
      font-size: 60px
      line-height: 64px
      margin-bottom: 6px
    .name-sign
      color: #ADADAD
      font-size: 16px
      font-family: Myriad Pro
      font-weight: 400
    .tips
      font-size: 21px
      color: #4B4B4B
      margin: 48px 0
      div
        line-height: 38px
    .sign
      max-width: 400px
      font-size: 13px
      font-weight: normal
      color: #ADADAD
      
