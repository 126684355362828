.map-holder 
  position: relative
  width: 100%
  padding-top: 60px
  margin-bottom: var(--line-margin)
  .main-logo
    position: absolute
    z-index: 1
    left: 0
    top: 0
  .map-img
    width: 100%
  .title-box
      position: absolute
      z-index: 1
      bottom: 10px
      left: 0
      white-space: pre-line
      .title-tip
        font-size: 15px
        font-family: SourceHanSansSC
        font-weight: normal
        margin-bottom: 4px
      .title
        font-size: 25px
        font-family: SourceHanSansSC
        font-weight: normal

.right-top-sign
  text-align: right
  white-space: pre-line
  font-size: 6px
  font-family: SourceHanSansSC
  font-weight: normal
  margin-bottom: 83px