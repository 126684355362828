.follow-holder
  margin-bottom: var(--line-margin)
  .tips
    font-size: 35px
    font-family: Taipei Sans TC Beta
    font-weight: 300
    white-space: pre-line
    line-height: 40px
    margin-bottom: 10px
  .copyright-img
    width: 132px
    height: 15px
    margin-bottom: 15px
  .qrcode-img
    width: 108px
    height: 108px
    margin-bottom: 17px
  .platform-line
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    margin-bottom: var(--line-margin)
    .title-box
      flex: auto
      white-space: pre-line
      .title-tip
        font-size: 15px
        font-family: SourceHanSansSC
        font-weight: normal
        margin-bottom: 4px
      .title
        font-size: 25px
        font-family: SourceHanSansSC
        font-weight: normal
    .platform-logos
      flex: none
  .bottom-tip
    white-space: pre-line
    color: #FF5500
    font-size: 16px
    font-family: SourceHanSansSC
    font-weight: normal

.follow_box
  width: 100%
  img
    width: 100%
