@font-face {
  font-family: 'iconfont';
  src: url('//at.alicdn.com/t/font_2504536_kmtsd9joxs8.eot?t=1619073251826'); /* IE9 */
  src: url('//at.alicdn.com/t/font_2504536_kmtsd9joxs8.eot?t=1619073251826#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
      url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAAQIAAsAAAAACCwAAAO5AAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCDBgqEFINOATYCJAMMCwgABCAFhG0HNhsUB8gOJQXBwJAgiUlAPPy79nlfkplFcMQlVnw8gqwqW7ZAdqsQhasySML3D+VZk7andCsv5qA1s1b2oGLSTes0y9eJP1LB6+AX+N3ziXunf3qA81mWyxzj/FMXYBxQQHtjFFCgBRKQB9JyOPMqPLpuE2g1LBG2raqhA4xUkEWBOE677WBkyqnUdIZmoS7ZWcRtUGlOVySPccv/fPyzMkaSVBlZuf1YpQsQP5mvIF/+f9lhlwCq8fwQ95GxGFCIo6XB/aRhcTGpVt0oq2+sFSGNlWk08JWGuV7pDy8RNdLeDmahlPh51pYQ/FKScP77qNGCx/iC2f4Rtbws3qyJiipkxLmzQvzajcaNa43Bqy7nMlev1q9ev//8uN238dvza49aN25LELseidf4Wr7zCjdpv+MOv2HPEcuaDYY1fNaKdfJ1u+IF4TrXsd+yYfLcNfJzomndJtOmYxMvibRy+cWrW21rxS7KW9XJn873b9h8+Ty1vIPjBtZfkauuXbOuE0hhiuDcL4r7eH4Px+09/Tx6y4usc6/itr7swjRg0P4DeZvPbw9Y1j45FgP9juSZKnqGKskRSIndduBG5ppskzrjh0Gvedbf1Dgv/uRC3fJFulvz45ub6OHThybufrjt1oQ2TdJYicqHnYbr2o/Y4/srqyo/N+g/1FdWdlrNGuxj3/VOw8UKwqo1HQ4h9ZJTG623ModaZIOh+JrM9vYUOn8Ck3mySJY83RiSjfqfeYt6/JN+OJ7QuyTKa3Eb41YoJSCDdfTuH4gA5F6MwErJTsKT8TasFEaQJDF0d4McX9gOtbh8AezeTZJolh3ASjAHQONnAbkfACj8QnLo/37Hzrfh9bNVhf+a5QW8f5L30de2NzffvTv8TVXLrmJoiCsXTWkrrVR2xx/zJ0AueTVwtNeb/uaB1u2EZgMxkiZDyJpNkoVdjEqb1ag124hWi1Tvb9OHIRWlBfMWIYRux5B0eoes2xOysK9RGfQNte6QotXe0J3YZjpovJxBdha5oGMA0s5ggGIeFg8625C7x2dnkhqfTyImQmdgWnJqtlKNAogZY02k153OshSkmKAfVqH7kM8XhCEm6EFONrmbZUP5KSlU2ZOSnUE/wG3FQOxYiAvkMACiOQUFUG5/Fp57fxvErYePHVPDqUuTEEYEPTyUJllqC7LaGGjFuZZHInq5pWOxKBCFEeQHVSE/4qPFIChUPs0DcWIl6+6RD8mXgntRbY3J85v9z3cAWpHLcqTIUVSnu4MBJPOjQA8AAAAA')
      format('woff2'),
    url('//at.alicdn.com/t/font_2504536_kmtsd9joxs8.woff?t=1619073251826') format('woff'),
    url('//at.alicdn.com/t/font_2504536_kmtsd9joxs8.ttf?t=1619073251826') format('truetype'),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
      url('//at.alicdn.com/t/font_2504536_kmtsd9joxs8.svg?t=1619073251826#iconfont') format('svg'); /* iOS 4.1- */
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconphone:before {
  content: '\e672';
}

.iconmenu:before {
  content: '\e700';
}
