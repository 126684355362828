.news-holder 
  margin-bottom: var(--line-margin)
  
.title-news-holder
  margin: 80px 0
  .title-news
    position: relative
    box-sizing: border-box
    width: 50%
    padding-left: 40px
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    font-size: 21px
    font-family: TaipeiSansTCBeta
    font-weight: 400
    color: #9B9B9B
    cursor: pointer
    &:before
      content: ''
      position: absolute
      left: 20px
      top: 50%
      transform: translate(-50%,-50%)
      width: 9px
      height: 9px
      border-radius: 50%
      background-color: var(--main-color)

.split-line
  width: 100%
  display: flex
  flex-direction: row
  margin-bottom: var(--line-margin)
  >div
    flex: auto
    margin-right: var(--line-margin)
    overflow: hidden
  >div:last-child 
    margin-right: 0
