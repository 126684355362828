.platform-logos-holder 
  position: relative
  overflow: hidden
  img
    width: 325px
    height: 64px
  .button-area
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    .plateform-button
      display: block
      flex: none
      width: 64px
      height: 64px
  

