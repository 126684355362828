.declaration-holder 
  display: flex
  flex-direction: row
  align-items: flex-start
  justify-content: space-between
  margin-bottom: 16px
  .left-logo
    flex: none
    width: 166px
    height: 184px
  .info-box
    flex: auto
    position: relative
    box-sizing: border-box
    height: 184px
    padding: 0 0 6px 17px
    font-weight: 300
    font-family: SourceHanSansSC
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
    white-space: pre-line
    *
      flex: none
    .border-tip
      width: 100%
      color: #FF5500
      font-size: 12px
      font-family: SourceHanSansSC
    .title-tip
      font-size: 15px
      font-family: SourceHanSansSC
      font-weight: normal
      margin-bottom: 4px
    .title
      font-size: 25px
      font-family: SourceHanSansSC
      font-weight: normal
      @media (max-width: 1100px) 
        font-size: 23px
    .content
      margin: 0 0 auto 0
      font-size: 18px
      line-height: 28px
      font-family: Taipei Sans TC Beta
      font-weight: 300

.declaration-img
  width: 100%