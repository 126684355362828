@import url(./iconfont.css);

/* html {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
} */

@font-face {  
  font-family: SourceHanSansSC;
  src: url('./static/SourceHanSansSC-VF.ttf');
}

body {
  margin: 0;
  padding: 25px;
  padding-bottom: 0px;
  /* css-vars */
  --main-color: rgb(233, 72, 22);
  --line-margin: 26px;
}

a {
  text-decoration: none;
  color: #333;
}

.App {
  width: 100%;
}

.content-holder {
  max-width: 1080px;
  /* max-width: 1440px; */
  margin: 0 auto;
}

.head-pic {
  width: 100%;
  margin-bottom: var(--line-margin);
}

.text-right {
  text-align: right;
}

.main-logo {
  width: 55px;
}

.line-margin {
  margin-bottom: var(--line-margin);
}

.border-tip-right {
  width: 132px;
  height: 15.5px;
}

.border-tip-left {
  width: 446px;
  height: 32px;
}

.line-mb {
  margin-bottom: var(--line-margin);
}

.button {
  cursor: pointer;
  color: white;
  transition: all 0.2s ease;
}

.button:hover {
  color: #d6d5d5;
}

@media (max-width: 1100px) {
  .product-holder .info-box .name {
    font-size: 56px;
  }

  .router-line .router-item {
    font-size: 20px;
  }

  .home-declaration-holder .info-box .motto {
    font-size: 30px;
  }

  .declaration-holder .info-box .content,
  .home-declaration-holder .info-box .content {
    font-size: 16px;
    letter-spacing: -0.5px;
  }
}