.news-pic
  position: relative
  // background: yellow
  .trangle-placeholder
    width: 100%
    height: 100%
    padding-top: 100%
    &>div
      width: 100%
      height: 100%
      position: absolute
      display: flex
      flex-direction: column
      align-items: center
      left: 0
      top: 0
      text-align: center
      .image
        width: 100%
        height: 340px
        background-repeat: no-repeat
        background-position: center
        background-size: cover
      .tip
        width: 100%
        height: 273px
        background-repeat: no-repeat
        background-position: center
        background-size: contain
        cursor: pointer
      .title
        font-size: 20px
        margin: 20px 0
      .date
        font-size: 14px
        margin-bottom: 20px
      .text
        text-align: initial
        color: balck
        word-break: break-all
      .button
        cursor: pointer
        margin-top: 40px
        width: 120px
        line-height: 26px
        border: 1px solid black
        color: var(--main-color)
        &:hover
          background: var(--main-color)
          color: white
          border-color: var(--main-color)
