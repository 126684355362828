.banner-holder
  margin-bottom: var(--line-margin)
  *
    outline: none
  .custom-prev,.custom-next
    top: 50%
    position: absolute
    z-index: 2
    width: 60px
    height: 60px
    background: #cecbcba8
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    margin-top: -30px
    transition: all .2s
    &>span
      font-size: 30px
      color: white
    &:hover
      background: #eeeeee8a
  .custom-prev
    left: -30px
  .custom-next
    right: -30px
  img
    width: 100%
  .slick-arrow
    z-index: 1
    width: 40px
    height: 40px
    &::before
      font-size: 40px
  .slick-prev
    left: 4px
  .slick-next
    right: 4px
